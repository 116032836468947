<template>
  <v-row dense class="b-substipulator">
    <v-col cols="12" xl="7" lg="7" md="7" sm="7">
      <v-switch
        hide-details
        color="primary"
        class="b-substipulator--switch"
        :label="question"
        v-model="active"
        @change="onChange"
      />
    </v-col>
    <v-col
      class="b-substipulator__content"
      cols="12"
      xl="5"
      lg="5"
      md="5"
      sm="5"
      v-if="parentId"
    >
      <h6 class="b-substipulator__content--title">
        Subestipulante selecionado:
      </h6>
      <span class="b-substipulator__content--text">
        {{ parentId.socialName }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="secondary"
              @click="dialog = true"
              v-bind="attrs"
              v-on="on"
            >
              fa-regular fa-pen-to-square
            </v-icon>
          </template>
          <span>Alterar seleção do subestipulante</span>
        </v-tooltip>
      </span>
    </v-col>

    <v-dialog
      persistent
      transition="dialog-bottom-transition"
      :max-width="400"
      :retain-focus="false"
      :value="dialog"
      @input="closeModal()"
    >
      <v-card>
        <v-card-title class="body-1 font-weight-regular">
          Selecione o subestipulante
          <v-spacer />
          <v-btn icon @click="closeModal()">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <BaseCustomersByContract
                hide-details
                :returnObject="true"
                :contractId="contractId"
                v-model="parentId"
                @change="onChangeParent"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    question: {
      type: String,
      require: true,
      default: 'Deseja visualizar ou incluir por subestipulante?'
    },
    contractId: {
      type: String
    }
  },

  data: () => ({
    active: false,
    parentId: null,
    dialog: false
  }),

  methods: {
    onChange() {
      if (!this.active) {
        this.parentId = null;
        this.$emit('sub', null);
      } else {
        this.dialog = true;
      }
    },

    onChangeParent() {
      this.dialog = false;
      this.$emit('sub', this.parentId.id);
    },

    closeModal() {
      this.dialog = false;

      if (!this.parentId) {
        this.active = false;
      }
    }
  }
};
</script>
<style lang="scss">
.b-substipulator {
  border-bottom: 1px solid;
  padding-bottom: 10px;
  margin-bottom: 20px;

  &--switch {
    margin-top: 4px;

    .v-label {
      font-size: 14px;
    }
  }

  &__content {
    text-align: right;

    &--text {
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .v-icon {
        margin-left: 5px;
      }
    }
  }
}

.theme--light {
  .b-substipulator {
    border-color: rgba(0, 0, 0, 0.12);
  }
}
.theme--dark {
  .b-substipulator {
    border-color: hsla(0, 0%, 100%, 0.12);
  }
}
</style>
